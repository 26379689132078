import { throttle } from "lodash"

up.compiler('.navigation-bar', function(navigationBar) {
  let isScrolledDown = false
  const opaqueClass = 'is-opaque'
  const scrollThreshold = 100

  const toggleOpaqueClass = function() {
    if (isScrolledDown) {
      navigationBar.classList.add(opaqueClass)
    }
    else {
      navigationBar.classList.remove(opaqueClass)
    }
  }

  const checkScrollPosition = function() {
    let scrollY = window.scrollY || document.documentElement.scrollTop
    isScrolledDown = scrollY > scrollThreshold
    toggleOpaqueClass()
  }

  const handleScroll = throttle(() => { checkScrollPosition() }, 250)
  document.addEventListener('scroll', handleScroll, {
    passive: true
  })

  return () => {
    handleScroll.cancel()
    document.removeEventListener('scroll', handleScroll, {
      passive: true
    })
  }
})
